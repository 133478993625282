import React,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
//import { toast } from 'react-toastify';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Form from 'react-bootstrap/Form';
//import Button from 'react-bootstrap/Button';

//INTERNAL
//import RestServer from '../../components/cRestServer';
import {orgurl, producturl}  from '../../environment';
//import MyPaymentForm from './paymenform';
import {cartfunctions} from '../util/cartFunctions';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMinusCircle, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
//import { faCcMastercard, faCcVisa, faCcAmex } from "@fortawesome/free-brands-svg-icons"

//STORES
import cartDataStore from '../../stores/zcartdatastore';



function TicketPurchase(){

  //const[userEmailAddress, setUserEmailAddress]=useState("");
  const[taxes, setTaxes]=useState(null);
  //const [userData, setUserData]=useState(null);
  const[products, setProducts]=useState([]);
  const[venues, setVenues]=useState(null);
  const[cartData, setCartData] = useState(null);
  const[events, setEvents]=useState(null);
  //const[cartCal, setCartCal]=useState(null)
  //const[cartSubTotal, setCartSubTotal]=useState(null);
  //const[cartTaxes, setCartTaxes]=useState(null);
  //const[cartTotal, setCartTotal]=useState(null);


  const history = useNavigate();
  const cdata = JSON.parse(localStorage.getItem('usercart'));
  const udata=JSON.parse(localStorage.getItem('userdata'));
 

  const getRemoteData=(url)=>{
    return new Promise((resolve, reject)=>{
      fetch(url).then(response=>response.json()).then((data)=>{resolve(data)})
    })
  }

  //get Event Name
  const handleEventName=(eventId)=>{
    var evnt={}
    events?.map((e)=>{
      if(e.eventId ===eventId){
        evnt=e;
      }
    })
    return evnt.eventname;
  }

  const calulateCart=(rtntype)=>{
    var subtotalcal=0;
    var taxtotalcal=0;
    var rtn=null;

    let CADDollar = new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
  });

    cdata?.products.map((prod, pindex)=>{
      let product= products?.find(o=>o.productId===prod.productid);
      let tax=taxes?.find(t=>t.companyTaxId===prod.taxesId);

      var tempsubtotalraw=product?.price*prod.quanity;
      var temptaxesraw=tempsubtotalraw*tax?.percentage;

      //SUM Price
      subtotalcal=(subtotalcal+tempsubtotalraw);

      //SUM Tax
      taxtotalcal=(taxtotalcal+temptaxesraw);

    });

    if(rtntype===0){
      subtotalcal=CADDollar.format(subtotalcal);
      subtotalcal=subtotalcal.toString().replace("$", "");
      rtn=subtotalcal;
    }

    if(rtntype===1){
      taxtotalcal=CADDollar.format(taxtotalcal);
      taxtotalcal=taxtotalcal.toString().replace("$", "");
      rtn=taxtotalcal
    }

    if(rtntype===2){
     var total=(parseFloat(subtotalcal)+parseFloat(taxtotalcal));
     total=CADDollar.format(total);
     total=total.toString().replace("$", "");
     rtn=total;
      
     }

    return rtn;

  }
  const handleProceed=()=>{
    //check if person is login
    const userData = JSON.parse(localStorage.getItem('usercart'));

    if(userData===null){ history("/login", {replace:true})}

    if(cartData===null){
      history("/", {replace:true})
    }else{
      const subTotalCal=calulateCart(0);
      const subTaxCal=calulateCart(1);
      const totalCal=calulateCart(2);

      const cartPay={
        subTotal: subTotalCal,
        surcharge:"$0.00",
        taxes:subTaxCal,
        total:totalCal
      }

      localStorage.setItem("cartpayData", JSON.stringify(cartPay));
      history("/cartpay", {replace:true})

    }

  }

  const getObjByIdVal=(obj, objidname, objid, keys)=>{

    let venue=null;
    obj?.map((ven)=>{
      if(ven[objidname] ==objid){
        venue=keys.reduce((xs, x) => (xs && xs[x] !== null && xs[x] !== undefined ? xs[x] : null),ven);
      }
     })
     return venue;
  }

  const incCart=(e)=>{
    cartfunctions.addToCart(e).then(()=>{setCartData(cartfunctions.getCart())});
   }
   const decCart=(e)=>{
    cartfunctions.decToCart(e).then(()=>{setCartData(cartfunctions.getCart())});
   }



  
  useEffect(() => {
    setCartData(cdata);

    var tempEventIds=[];
    var tempVenueIds=[];
    var tempTaxIds=[];

    //GET PROD and EVENTIDS, VENUEIDS, TAXIDS
    const prods_data=[];
    cdata?.products?.map((p)=>{
      console.info("cdata?.products?.map->p:");
      console.info(p);

      tempEventIds.push(p.eventId);
      tempVenueIds.push(p.venueid);
      tempTaxIds.push(p.taxesId);
      prods_data.push(getRemoteData(producturl+'/api/prod/productbyid/'+p.productid))
    })

    Promise.all(prods_data).then((allproddata)=>{
      console.info("allproddata: ");
      console.info(allproddata);
      setProducts(allproddata);
    })


    //GET EVENTS
    const uniqueEventIds = tempEventIds.filter((x, i, a) => a.indexOf(x) == i);
    const event_data=[];
    uniqueEventIds.map((e)=>{
      event_data.push(getRemoteData(producturl+'/api/prod/event/'+e))
    })

    Promise.all(event_data).then((alleventdata)=>{
      console.info("alleventdata: ");
      console.info(alleventdata);
      setEvents(alleventdata);
    })

    //GET VENUES
    const uniqueVenuesIds = tempVenueIds.filter((x, i, a) => a.indexOf(x) == i);
    const venue_data=[];
    uniqueVenuesIds.map((v)=>{
      venue_data.push(getRemoteData(orgurl+'/api/org/byvenueid/'+v))
    })

    Promise.all(venue_data).then((allvenuedata)=>{
      setVenues(allvenuedata);
    })

    //GET TAXES
    const uniqueTaxIds = tempTaxIds.filter((x, i, a) => a.indexOf(x) == i);
    const taxes_data=[];
    uniqueTaxIds.map((t)=>{
      taxes_data.push(getRemoteData(orgurl+'/api/org/getcompanytax/'+t))
    })

    Promise.all(taxes_data).then((alltaxesdata)=>{
      setTaxes(alltaxesdata);
    })

  },[]);
   

    return(
        <React.Fragment>
          
             <Row>
                  <Col lg={12}>
                    <Row>
                    <Col lg={7}>
                        <Row className="p-b-25"><Col className="font-size-150 font-heavy8">Your Shopping Cart</Col></Row>
                        <Row>
                            <Col>
                            {
                              products?.map((p, pindex)=>{
                                return<Row className="p-b-50">
                                    <Col>
                                      <Row className="p-b-5 font-size-100 font-heavy9">
                                        <Col>{handleEventName(p?.eventId)}&nbsp; - &nbsp;{getObjByIdVal(venues,'venueid', p.venueid, ['venuename'])} &nbsp; &nbsp;</Col>
                                      </Row>
                                      <Row className="p-b-10 font-size-75 font-heavy">
                                        <Col>
                                          {getObjByIdVal(venues,'venueid', p.venueid, ['venueaddress','address1'])}, &nbsp;
                                          {getObjByIdVal(venues,'venueid', p.venueid, ['venueaddress','city'])}, &nbsp;
                                          {getObjByIdVal(venues,'venueid', p.venueid, ['venueaddress','state'])} &nbsp;
                                        </Col>
                                      </Row>
                                      <Row >
                                        <Col>
                                        {/*NEED TO MAKE THIS INTO A LOOP AS YOU COULD BUY MULTI TICKET TYPE IN A SAME EVENT */}
                                          <Row>
                                            <Col lg={8} className="font-size-100 p-t-10">
                                             
                                              <FontAwesomeIcon icon={faTicketAlt} />&nbsp;&nbsp;&nbsp;&nbsp;{p?.productName}
                                            </Col>
                                            <Col className="font-size-75 font-heavy ">
                                              <button className="container-1-25 font-size-75 font-heavy9" onClick={()=>{incCart(cartData?.products[pindex])}}>+</button> 
                                              &nbsp; {cartData?.products[pindex].quanity} &nbsp; 
                                              <button className="container-1-25 font-size-75 font-heavy9" onClick={()=>{decCart(cartData?.products[pindex])}}>-</button> </Col>
                                            <Col className="p-t-10">
                                                <span ><FontAwesomeIcon icon={faMinusCircle}  /></span>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                              })
                            }
                                
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4} >
                      <Row>
                      
                        <Col>
                            <Row className="p-b-25">
                              <Col className="font-size-150 font-heavy8"> Order Summary</Col>
                            </Row>
                            <Row>
                              <Col className="container-1 font-size-100 font-heavy">
                                <Row className="p-t-25">
                                    <Col className="p-l-50">Subtotal</Col>
                                    <Col className="right p-r-50">${calulateCart(0)}</Col>
                                </Row>
                                <Row className="p-t-10">
                                    <Col className="p-l-50">Surcharge</Col>
                                    <Col className="right p-r-50">$0.00</Col>
                                </Row>
                                <Row className="p-t-10">
                                    <Col className="p-l-50">Taxes</Col>
                                    <Col className="right p-r-50">${calulateCart(1)}</Col>
                                </Row>
                                <Row className="p-t-25 font-heavy9 p-b-25">
                                    <Col className="p-l-50">Total</Col>
                                    <Col className="right p-r-50">${calulateCart(2)}</Col>
                                </Row>
                              </Col>
                            </Row>
                        </Col>
    
                      </Row>
                      <Row className="p-t-25 p-b-25">
                        <Col><button  onClick={()=>{handleProceed()}} className="icon-text-container-2 font-size-125 font-heavy">Proceed To Checkout</button></Col>
                      </Row>
                     
                      

                    </Col>
                    </Row>
                  </Col>
                  <Col lg={1}></Col>
                </Row>
        </React.Fragment>
    );
}
export default TicketPurchase;